import React from 'react';

import {useTranslation} from 'react-i18next';
import {Button, Form} from 'react-bootstrap';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';

import {useTenantInfo} from 'modules/tenant/hooks';
import {formatAxiosErrorOrThrow} from 'shared/utils/error';
import {ICreateUserForm} from 'users/pages/CreateUser/CreateUser';
import {UserCreatedByType, UserRole} from 'modules/user/models/user';
import {Config} from 'shared/config';
import {createUserFromSignupPage} from 'modules/user/api/user';
import {useSignupPageSummaryFromUrl} from 'modules/signup-page/hooks/useSignupPageSummaryFromUrl';

import {FullScreenModal} from 'shared/components/FullScreenModal/FullScreenModal';
import {FullScreenModalTitle} from 'shared/components/FullScreenModal/FullScreenModalTitle';

import './styles.scss';

const signupSchema = Yup.object().shape({
    firstname: Yup.string().required().min(1),
    lastname: Yup.string().required().min(1),
    byline: Yup.string().optional(),
    phone_number: Yup.string().optional(),
    email: Yup.string().email().required(),
    password: Yup.string().optional().min(Config.passwordLengthMin).max(Config.passwordLengthMax),
    passwordConfirm: Yup.string().optional().min(Config.passwordLengthMin).max(Config.passwordLengthMax),
    tag_ids: Yup.array(Yup.string().required()),
    permissions: Yup.array(Yup.string().required()),
    role: Yup.string().oneOf(Object.values(UserRole)).required(),
});

const signupFormDefaults: ICreateUserForm = {
    firstname: '',
    lastname: '',
    email: '',
    byline: '',
    phone_number: '',
    tag_ids: [],
    password: '',
    passwordConfirm: '',
    role: UserRole.Student,
    permissions: [],
    created_by_type: UserCreatedByType.SIGNUP,
};

export const Signup = () => {
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const {tenantInfo} = useTenantInfo();
    const signupPage = useSignupPageSummaryFromUrl(tenantInfo.id);

    const {register, handleSubmit, formState: {errors}, setError} = useForm<ICreateUserForm>({
        resolver: yupResolver(signupSchema),
        defaultValues: signupFormDefaults,
    });

    const navigate = useNavigate();

    const onSubmit = ({role, ...userCreate}: ICreateUserForm) => {
        if (isSubmitting || !signupPage) {
            return;
        }
        if (userCreate.password !== userCreate.passwordConfirm) {
            setError(
                'passwordConfirm',
                {message: t('signup.passwordConfirm.error', 'Passwords do not match.')},
                {shouldFocus: true},
            );
            return;
        }
        setIsSubmitting(true);
        (async () => {
            try {
                await createUserFromSignupPage(tenantInfo.id, signupPage.id, {
                    roles: [role],
                    ...userCreate,
                });
                // redirect to login page
                navigate('/login');
                toast.success(t('signup.success', 'Signup successful! Please login'));
            } catch (e) {
                toast.error(formatAxiosErrorOrThrow(t(
                    'signup.submit.error', 'There was an error',
                ), e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <FullScreenModal close={false} size="md">
            <FullScreenModalTitle>
                {t('common.signupPage.title', 'Sign up to Young Eyes {{tenantName}}', {tenantName: tenantInfo.name})}
            </FullScreenModalTitle>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex">
                    <Form.Group className="Signup--first-name" controlId="signup.firstname">
                        <Form.Label>{t('common.firstname', 'First Name')}</Form.Label>
                        <Form.Control
                            autoFocus
                            type="text"
                            placeholder={t('common.firstname', 'First Name')}
                            isInvalid={!!errors.firstname}
                            {...register('firstname')}
                        />
                        {(errors.firstname) && (
                            <Form.Control.Feedback type="invalid">
                                {errors.firstname.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className="Signup--last-name" controlId="signup.lastname">
                        <Form.Label>{t('common.lastname', 'Last Name')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('common.lastname', 'Last Name')}
                            isInvalid={!!errors.lastname}
                            {...register('lastname')}
                        />
                        {(errors.lastname) && (
                            <Form.Control.Feedback type="invalid">
                                {errors.lastname.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </div>
                <Form.Group controlId="signup.email">
                    <Form.Label>{t('common.emailAddress', 'Email Address')}</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder={t('common.emailAddress', 'Email Address')}
                        isInvalid={!!errors.email}
                        {...register('email')}
                    />
                    {(errors.email) && (
                        <Form.Control.Feedback type="invalid">
                            {errors.email.message}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group controlId="signup.password">
                    <Form.Label>{t('common.password', 'Password')}</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder={t('common.password', 'Password')}
                        isInvalid={!!errors.password}
                        {...register('password')}
                    />
                    {(errors.password) && (
                        <Form.Control.Feedback type="invalid">
                            {errors.password.message}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group controlId="signup.password.confirm">
                    <Form.Label>{t('common.confirmPassword', 'Confirm Password')}</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder={t('common.password', 'Password')}
                        isInvalid={!!errors.passwordConfirm}
                        {...register('passwordConfirm')}
                    />
                    {(errors.passwordConfirm) && (
                        <Form.Control.Feedback className="mt-1" type="invalid">
                            {errors.passwordConfirm.message}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <div className="text-center">
                    <Button className="Signup--signup-button" type="submit" variant="primary" disabled={isSubmitting}>
                        {isSubmitting ? (
                            t('signup.submitButton', 'Signing Up...', {context: 'busy'})
                        ) : (
                            t('signup.submitButton', 'Sign Up')
                        )}
                    </Button>
                    <div className="mt-3">
                        {t('signup.hasAccount', 'Already have an account?')}
                        <a className="ml-2" href="/login">{t('common.logIn', 'Log In')}</a>
                    </div>
                </div>
            </Form>
        </FullScreenModal>
    );
};