import React from 'react';

import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';

import {useTenantInfo} from 'modules/tenant/hooks';
import {formatAxiosErrorOrThrow} from 'shared/utils/error';
import {ICreateUserForm} from 'users/pages/CreateUser/CreateUser';
import {createUserFromSingleUseSignup} from 'modules/user/api/user';
import {useSingleUseSignupSummaryFromUrl} from 'modules/single-use-signup/hooks/useSingleUseSignupSummaryFromUrl';

import {FullScreenModal} from 'shared/components/FullScreenModal/FullScreenModal';
import {FullScreenModalTitle} from 'shared/components/FullScreenModal/FullScreenModalTitle';
import {LoadingPage} from 'shared/components/loading/LoadingPage/LoadingPage';
import {LoadingError} from 'shared/components/loading/LoadingError/LoadingError';
import {PublicSignupForm} from 'shared/pages/Signup/PublicSignup/PublicSignupForm';
import {EmailConfirmation} from 'shared/pages/Signup/EmailConfirmation';

import '../styles.scss';

export const PublicSignup = () => {
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [emailConfirmation, setEmailConfirmation] = React.useState<string | undefined>();

    const {tenantInfo} = useTenantInfo();
    const {singleUseSignupSummary, loading, error} = useSingleUseSignupSummaryFromUrl(tenantInfo.id);

    const navigate = useNavigate();

    const onSubmit = ({role, ...userCreate}: ICreateUserForm) => {
        if (isSubmitting || !singleUseSignupSummary || !emailConfirmation) {
            return;
        }
        setIsSubmitting(true);
        (async () => {
            try {
                await createUserFromSingleUseSignup(
                    tenantInfo.id,
                    singleUseSignupSummary.id,
                    {
                        roles: [role],
                        ...userCreate,
                    },
                    emailConfirmation,
                );
                // redirect to login page
                navigate('/login');
                toast.success(t('signup.success', 'Signup successful! Please login'));
            } catch (e) {
                toast.error(formatAxiosErrorOrThrow(t(
                    'signup.submit.error', 'There was an error',
                ), e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    if (loading) {
        return (
            <LoadingPage />
        );
    } else if (error) {
        return (
            <LoadingError />
        );
    } else {
        return (
            <FullScreenModal close={false} size="md">
                <FullScreenModalTitle className="mb-4">
                    {t('common.parentSignup.title', 'Sign up as a public user to Young Eyes {{tenantName}}', {tenantName: tenantInfo.name})}
                </FullScreenModalTitle>
                {!emailConfirmation ? (
                    <EmailConfirmation setEmail={setEmailConfirmation} />
                ) : (
                    <PublicSignupForm isSubmitting={isSubmitting} onSubmit={onSubmit} />
                )}
            </FullScreenModal>
        );
    }
};