import * as Yup from 'yup';
import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {yupResolver} from '@hookform/resolvers/yup';

import {UserCreatedByType, UserRole} from 'modules/user/models/user';
import {ICreateUserForm} from 'users/pages/CreateUser/CreateUser';
import {Config} from 'shared/config';
import {FullScreenModalDescription} from 'shared/components/FullScreenModal/FullScreenModalDescription';

const parentSignupFormSchema = Yup.object().shape({
    firstname: Yup.string().required().min(1),
    lastname: Yup.string().required().min(1),
    byline: Yup.string().optional(),
    phone_number: Yup.string().optional(),
    email: Yup.string().email().required(),
    password: Yup.string().optional().min(Config.passwordLengthMin).max(Config.passwordLengthMax),
    passwordConfirm: Yup.string().optional().min(Config.passwordLengthMin).max(Config.passwordLengthMax),
    tag_ids: Yup.array(Yup.string().required()),
    permissions: Yup.array(Yup.string().required()),
    role: Yup.string().oneOf(Object.values(UserRole)).required(),
});

const parentSignupFormDefaults: ICreateUserForm = {
    firstname: '',
    lastname: '',
    email: '',
    byline: '',
    phone_number: '',
    tag_ids: [],
    password: '',
    passwordConfirm: '',
    role: UserRole.Student,
    permissions: [],
    created_by_type: UserCreatedByType.SIGNUP,
};

interface IPublicSignupFormProps {
    isSubmitting: boolean;
    onSubmit: (val: ICreateUserForm) => void;
}

export const PublicSignupForm = ({isSubmitting, onSubmit}: IPublicSignupFormProps) => {
    const {t} = useTranslation();

    const {register, handleSubmit, formState: {errors}, setError} = useForm<ICreateUserForm>({
        resolver: yupResolver(parentSignupFormSchema),
        defaultValues: parentSignupFormDefaults,
    });

    const newSubmit = (form: ICreateUserForm) => {
        if (form.password !== form.passwordConfirm) {
            setError(
                'passwordConfirm',
                {message: t('signup.passwordConfirm.error', 'Passwords do not match.')},
                {shouldFocus: true},
            );
        } else {
            onSubmit(form);
        }
    };

    return (
        <Form onSubmit={handleSubmit(newSubmit)}>
            <FullScreenModalDescription className="Signup--text">
                {t('publicSignupForm.clarification', 'Enter the details of the individual who will be completing the course.')}
            </FullScreenModalDescription>
            <div className="d-flex">
                <Form.Group className="Signup--first-name" controlId="signup.firstname">
                    <Form.Label>{t('common.firstname', 'First Name')}</Form.Label>
                    <Form.Control
                        autoFocus
                        type="text"
                        placeholder={t('common.firstname', 'First Name')}
                        isInvalid={!!errors.firstname}
                        {...register('firstname')}
                    />
                    {(errors.firstname) && (
                        <Form.Control.Feedback type="invalid">
                            {errors.firstname.message}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group className="Signup--last-name" controlId="signup.lastname">
                    <Form.Label>{t('common.lastname', 'Last Name')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('common.lastname', 'Last Name')}
                        isInvalid={!!errors.lastname}
                        {...register('lastname')}
                    />
                    {(errors.lastname) && (
                        <Form.Control.Feedback type="invalid">
                            {errors.lastname.message}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
            </div>
            <Form.Group controlId="signup.email">
                <Form.Label>{t('common.emailAddress', 'Email Address')}</Form.Label>
                <Form.Control
                    type="email"
                    placeholder={t('common.emailAddress', 'Email Address')}
                    isInvalid={!!errors.email}
                    {...register('email')}
                />
                {(errors.email) && (
                    <Form.Control.Feedback type="invalid">
                        {errors.email.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="signup.password">
                <Form.Label>{t('common.password', 'Password')}</Form.Label>
                <Form.Control
                    type="password"
                    placeholder={t('common.password', 'Password')}
                    isInvalid={!!errors.password}
                    {...register('password')}
                />
                {(errors.password) && (
                    <Form.Control.Feedback type="invalid">
                        {errors.password.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group controlId="signup.password.confirm">
                <Form.Label>{t('common.confirmPassword', 'Confirm Password')}</Form.Label>
                <Form.Control
                    type="password"
                    placeholder={t('common.password', 'Password')}
                    isInvalid={!!errors.passwordConfirm}
                    {...register('passwordConfirm')}
                />
                {(errors.passwordConfirm) && (
                    <Form.Control.Feedback className="mt-1" type="invalid">
                        {errors.passwordConfirm.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <div className="text-center">
                <Button className="Signup--signup-button" type="submit" variant="primary" disabled={isSubmitting}>
                    {isSubmitting ? (
                        t('signup.submitButton', 'Signing Up...', {context: 'busy'})
                    ) : (
                        t('signup.submitButton', 'Sign Up')
                    )}
                </Button>
                <div className="mt-3">
                    {t('signup.hasAccount', 'Already have an account?')}
                    <a className="ml-2" href="/login">{t('common.logIn', 'Log In')}</a>
                </div>
            </div>
        </Form>
    );
};