import React from 'react';

import {useRecoilValue} from 'recoil';
import {Nav, Navbar as BootstrapNavbar} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {navbarState} from 'shared/state/navbar';
import {useAuth} from 'shared/hooks/useAuth';
import {Spinner} from 'shared/components/Spinner/Spinner';
import {Link} from 'react-router-dom';

import {NavbarAccountDropdown} from './NavbarAccountDropdown';
import {NavbarNotifications} from './NavbarNotifications/NavbarNotifications';
import {NavbarSearch} from './NavbarSearch/NavbarSearch';
import {NavbarProfilePicture} from './NavbarProfilePicture';
import {NavbarSidemenu} from './NavbarSidemenu/NavbarSidemenu';

import './style.scss';

export const Navbar = () => {
    const {isAuthenticated} = useAuth();
    const [isSidemenuOpen, setIsSidemenuOpen] = React.useState<boolean>(false);
    const showNavbar = useRecoilValue(navbarState);

    const onToggleSidemenuClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        setIsSidemenuOpen(!isSidemenuOpen);
    };

    if (!showNavbar) {
        return null;
    }

    return (
        <>
            {isAuthenticated && (
                <NavbarSidemenu isOpen={isSidemenuOpen} onClose={() => setIsSidemenuOpen(false)} />
            )}
            <BootstrapNavbar expand={true}>
                {isAuthenticated && (
                    <Nav>
                        <Nav.Link href="#sidemenu" onClick={onToggleSidemenuClick}>
                            <FontAwesomeIcon icon={faBars} size="lg" />
                        </Nav.Link>
                    </Nav>
                )}
                <NavbarProfilePicture />
                <Link style={{color: 'unset'}} to="/">
                    <img
                        className="align-self-center m-0 d-non d-sm-flex text-center"
                        src={'/assets/images/YoungEyesLogo.png'}
                        alt={'Young Eyes Logo'}
                        style={{width: '35%'}}
                    />
                </Link>
                <Nav className="align-items-center ms-auto d-non d-sm-flex ml-auto">
                    {isAuthenticated && (
                        <>
                            <NavbarSearch />
                            <React.Suspense
                                fallback={(
                                    <Spinner />
                                )}
                            >
                                <NavbarNotifications />
                            </React.Suspense>
                            <NavbarAccountDropdown />
                        </>
                    )}
                </Nav>
            </BootstrapNavbar>
        </>
    );
};
