import React from 'react';

import classNames from 'classnames';

interface IFullScreenModalDescriptionProps {
    className?: string;
}

export const FullScreenModalDescription = ({
    children,
    className,
}: React.PropsWithChildren<IFullScreenModalDescriptionProps>) => {
    return (
        <p className={classNames(className, 'FullScreenModal__description')}>{children}</p>
    );
};
