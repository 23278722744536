import {yupResolver} from '@hookform/resolvers/yup';
import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import './styles.scss';

const emailConfirmationSchema = Yup.object().shape({
    confirmEmail: Yup.string().email(),
});

interface IEmailConfirmationSchema {
    confirmEmail: string;
}

interface IEmailConfirmationProps {
    setEmail: (val: string) => void;
}

export const EmailConfirmation = ({setEmail}: IEmailConfirmationProps) => {
    const {t} = useTranslation();

    const {handleSubmit, formState: {errors}, register} = useForm<IEmailConfirmationSchema>({
        resolver: yupResolver(emailConfirmationSchema),
        defaultValues: {
            confirmEmail: '',
        },
    });

    const onSubmit = (data: IEmailConfirmationSchema) => {
        try {
            setEmail(data.confirmEmail);
        } catch (error) {
            toast.error(t('emailConfirmation.error', 'Email format wrong'));
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex align-items-center justify-content-between">
                <Form.Label className="ConfirmEmail--text mb-1">
                    {t('emailConfirmation.confirmEmailAddress', 'Confirm Email Address')}
                </Form.Label>
                <span className="ConfirmEmail--text text-muted mb-1">
                    {t('emailConfirmation.test', 'Use the same email used when purchasing the course.')}
                </span>
            </div>
            <Form.Group controlId="emailConfirmation.email" className="mb-3">
                <Form.Control
                    type="email"
                    placeholder={t('emailConfirmation.confirmEmailAddress', 'Enter your email address...')}
                    isInvalid={!!errors.confirmEmail}
                    {...register('confirmEmail')}
                />
                {errors.confirmEmail && (
                    <Form.Control.Feedback type="invalid">
                        {errors.confirmEmail.message}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <div className="text-center">
                <Button className="ConfirmEmail--submit-button" type="Submit" variant="primary">
                    {t('signup.submitButton', 'Confirm')}
                </Button>
            </div>
        </Form>
    );
};